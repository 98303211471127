<template>
  <DashboardAuthLayout>
    <template #header>
      <img src="@/assets/images/careercolor.svg" alt="Logo" />
    </template>

    <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
      <div class="flex flex-row md:flex-row justify-between items-center">
        <div class="flex flex-col">
          <h1 class="font-cooper font-medium text-3xl text-[#090E11]">
            Enter your Password
          </h1>
          <span class="font-GTWalsheimPro font-normal text-sm text-[#090E11]/80"
            >Secure your account.</span
          >
        </div>
        <div>
          <circle-progress
            :percent="66.6"
            :size="64"
            :border-width="6"
            :border-bg-width="6"
            fill-color="#B34C99"
            empty-color="#B34C991F"
          >
            <span class="flex flex-row items-center justify-between">
              <span class="font-bold text-3xl text-[#B34C99]">2</span>
              <span class="font-normal text-base text-black/40">/3</span>
            </span>
          </circle-progress>
        </div>
      </div>

      <div>
        <form @submit.prevent="goNext">
          <div class="pt-4 flex flex-col">
            <label
              for="password-icon"
              class="block mb-2 text-sm text-brand-dark-blue/80 font-semibold"
              >Create Password</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <LockSvg class="text-[#090E11]/20" />
              </div>
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password-icon"
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-0 focus:border-0 block w-full px-10 p-3"
                v-model="v$.password.$model"
                required
                minlength="8"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                >
                  <EyeSlashSvg
                    v-if="!showPassword"
                    @click="showPassword = true"
                    fill="black"
                  />
                  <times-svg v-else @click="showPassword = false" />
                </div>
              </div>
              <ul class="list-disc text-sm whitespace-nowrap text-red-500">
                <li v-for="error in v$.password.$errors" :key="error.$message">
                  {{ error.$message }}
                </li>
              </ul>
            </div>
          </div>

          <div class="pt-4 flex flex-col">
            <label
              for="password-icon-confirm"
              class="block mb-2 text-sm text-brand-dark-blue/80 font-semibold"
              >Confirm Password</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <LockSvg class="text-[#090E11]/20" />
              </div>
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                id="password-icon-connfirm"
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-0 focus:border-0 block w-full px-10 p-3"
                v-model="v$.password_confirmation.$model"
                required
                minlength="8"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                >
                  <EyeSlashSvg
                    v-if="!showConfirmPassword"
                    @click="showConfirmPassword = true"
                    fill="black"
                  />
                  <times-svg v-else @click="showConfirmPassword = false" />
                </div>
              </div>
              <ul class="list-disc text-sm whitespace-nowrap text-red-500 mb-4">
                <li
                  v-for="error in v$.password_confirmation.$errors"
                  :key="error.$message"
                >
                  {{ error.$message }}
                </li>
              </ul>
            </div>
          </div>

          <div class="pt-4">
            <button
              type="submit"
              class="w-full bg-brand-primary text-white p-4 rounded-lg disabled:opacity-60"
              :disabled="isDisabled"
            >
              Proceed <ButtonLoader :loading="loading" />
            </button>
          </div>
        </form>
      </div>
    </div>
  </DashboardAuthLayout>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { errorMessage, alert } from "@/utils/helper";
import LockSvg from "@/assets/icons/lock.svg?inline";
import EyeSlashSvg from "@/assets/icons/eye-slash.svg?inline";
import TimesSvg from "@/assets/icons/times.svg?inline";
import DashboardAuthLayout from "@/layouts/DashboardAuthLayout.vue";
import CircleProgress from "@/components/CircleProgress.vue";
import ButtonLoader from "@/components/ButtonLoader.vue";

const showConfirmPassword = ref(false);
const showPassword = ref(false);
const router = useRouter();
const store = useStore();
const loading = ref(false);
const submittingForm = ref(false);

const passwordDetails = ref({
  password_confirmation: "",
  password: "",
});

const isDisabled = computed(() => {
  const { password, password_confirmation } = passwordDetails.value;
  return (
    !v$.value.$anyDirty ||
    !password ||
    !password_confirmation ||
    password !== password_confirmation ||
    submittingForm.value === true
  );
});

const passwordIsSame = (password) => {
  return password === passwordDetails.value.password;
};

const rules = {
  password: {
    required,
    upperCase: helpers.withMessage(
      () => `Include at least one uppercase letter`,
      (value) => /(?=.*[A-Z])/.test(value)
    ),
    minLength: minLength(8),
    lowerCase: helpers.withMessage(
      () => `Include at least one lowercase letter`,
      (value) => /(?=.*[a-z])/.test(value)
    ),
    number: helpers.withMessage(
      () => `Include at least one number`,
      (value) => /(?=.*?[0-9])/.test(value)
    ),
    specialCharacter: helpers.withMessage(
      () => `Include at least a special character`,
      (value) => /(?=.*?[#?!@$%^&*-])/.test(value)
    ),
  },
  password_confirmation: {
    required,
    sameAsPassword: helpers.withMessage("Password does not match", passwordIsSame),
  },
};

const goNext = async () => {
  try {
    if (submittingForm.value === true) return;
    submittingForm.value = true
    if (!v$.value.$anyDirty) return;
    loading.value = true;
    let existing = localStorage.getItem("details");
    existing = existing ? JSON.parse(existing) : {};
    existing["password"] = passwordDetails.value.password;
    existing["password_confirmation"] = passwordDetails.value.password_confirmation;
    localStorage.setItem("details", JSON.stringify(existing));
    const userDetails = JSON.parse(localStorage.getItem("details"));
    await store.commit('global/setForm', {key: 'email', value: userDetails.email});
    await store.dispatch("auth/register", userDetails);
    localStorage.removeItem("details");
    router.push("/register-verify");
  } catch (error) {
    loading.value = false;
    submittingForm.value = false;
    alert(errorMessage(error), "error");
  }
};

const v$ = useVuelidate(rules, passwordDetails);
</script>

<style></style>
